<template>
<div class="container">
    <b-row class="mt-4">
        <b-col>
            <p><b-button variant="primary" @click="router.go(-1)"><v-icon name="caret-left" /> Go Back</b-button> <span>Map of {{location}}</span></p>
            <div id="map" class="mt-1 mt-sm-4">
            </div>
        </b-col>
    </b-row>   
</div>
</template>

<script>
import gmapsInit from '@/assets/gmapConfig.js'

// gMaps api key  !! Restrict this key in the future - korey
// AIzaSyDZbo8X_q8crHijPzXsjD6krj2luf-00Jw

export default {
    name: 'gMap',
    components: {
        
    },
    data() {
        return {
            map: null,
            marker: null,
            geoData: null,
            router: this.$router
        }
    },
    computed: {
        location () {
            return this.$route.params.location
        },
        
    },
    methods: {
        
    },
    async mounted() {
    try {
        const google = await gmapsInit();
        const geocoder = new google.maps.Geocoder()
        this.map = new google.maps.Map(document.getElementById("map"), {
            zoom: 20,
            mapTypeId: 'satellite'
        })
        
        geocoder.geocode({ address: this.location }, (results, status) => {
            if (status !== 'OK' || !results[0]) {
                throw new Error(status)
            }
            this.geoData = results
            this.map.setCenter(results[0].geometry.location)
            //this.map.fitBounds(results[0].geometry.viewport)

            //add marker
            this.marker = new google.maps.Marker({
                position: results[0].geometry.location,
                map: this.map,
            })
        })
        
    } catch (error) {
        console.error(error)
    }
  },
}
</script>

<style lang="scss" scoped>
    #map {
        width: 100%;
        height: 90vh;
        border: 2px blue solid;
    }
    
</style>